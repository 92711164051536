import * as React from "react";
import { createModule, isImageSlide, MODULE_CONTROLLER, PLUGIN_ZOOM } from "../../core/index.js";
import { ZoomContextProvider } from "./ZoomContext.js";
import { ZoomButtonsGroup } from "./ZoomButtonsGroup.js";
import { ZoomContainer } from "./ZoomContainer.js";
export const defaultZoomProps = {
    maxZoomPixelRatio: 1,
    zoomInMultiplier: 2,
    doubleTapDelay: 300,
    doubleClickDelay: 500,
    doubleClickMaxStops: 2,
    keyboardMoveDistance: 50,
    wheelZoomDistanceFactor: 100,
    pinchZoomDistanceFactor: 100,
    scrollToZoom: false,
};
export const Zoom = ({ augment, append }) => {
    augment(({ toolbar: { buttons, ...restToolbar }, render, carousel, animation, zoom, on, ...restProps }) => ({
        toolbar: {
            buttons: [React.createElement(ZoomButtonsGroup, { key: PLUGIN_ZOOM, labels: restProps.labels, render: render }), ...buttons],
            ...restToolbar,
        },
        render: {
            ...render,
            slide: (slide, offset, rect) => {
                var _a;
                return isImageSlide(slide) ? (React.createElement(ZoomContainer, { slide: slide, offset: offset, rect: rect, render: render, carousel: carousel, animation: animation, zoom: zoom, on: on })) : ((_a = render.slide) === null || _a === void 0 ? void 0 : _a.call(render, slide, offset, rect));
            },
        },
        zoom: {
            ...defaultZoomProps,
            ...zoom,
        },
        carousel,
        animation,
        on,
        ...restProps,
    }));
    append(MODULE_CONTROLLER, createModule(PLUGIN_ZOOM, ZoomContextProvider));
};
