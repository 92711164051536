import * as React from "react";
import { useController } from "../../core/index.js";
import { ZoomButton } from "./ZoomButton.js";
import { ACTION_ZOOM_IN, ACTION_ZOOM_OUT } from "./index.js";
export const ZoomButtonsGroup = ({ labels, render }) => {
    const zoomInRef = React.useRef(null);
    const zoomOutRef = React.useRef(null);
    const { transferFocus } = useController();
    const focusSibling = React.useCallback((sibling) => {
        var _a, _b;
        if (!((_a = sibling.current) === null || _a === void 0 ? void 0 : _a.disabled)) {
            (_b = sibling.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        else {
            transferFocus();
        }
    }, [transferFocus]);
    const focusZoomIn = React.useCallback(() => focusSibling(zoomInRef), [focusSibling]);
    const focusZoomOut = React.useCallback(() => focusSibling(zoomOutRef), [focusSibling]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ZoomButton, { ref: zoomInRef, key: ACTION_ZOOM_IN, zoomIn: true, labels: labels, render: render, onLoseFocus: focusZoomOut }),
        React.createElement(ZoomButton, { ref: zoomOutRef, key: ACTION_ZOOM_OUT, labels: labels, render: render, onLoseFocus: focusZoomIn })));
};
