import * as React from "react";
import { createModule, MODULE_CONTROLLER, PLUGIN_CAPTIONS } from "../../core/index.js";
import { CaptionsContextProvider } from "./CaptionsContext.js";
import { Description } from "./Description.js";
import { Title } from "./Title.js";
export const defaultCaptionsProps = {
    descriptionTextAlign: "start",
    descriptionMaxLines: 3,
};
export const Captions = ({ augment, addParent }) => {
    addParent(MODULE_CONTROLLER, createModule(PLUGIN_CAPTIONS, CaptionsContextProvider));
    augment(({ render: { slideFooter: renderFooter, ...restRender }, captions, styles, ...restProps }) => ({
        render: {
            slideFooter: (slide) => (React.createElement(React.Fragment, null, renderFooter === null || renderFooter === void 0 ? void 0 :
                renderFooter(slide),
                slide.title && React.createElement(Title, { styles: styles, title: slide.title }),
                slide.description && (React.createElement(Description, { styles: styles, description: slide.description, ...{ ...defaultCaptionsProps, ...captions } })))),
            ...restRender,
        },
        styles,
        ...restProps,
    }));
};
