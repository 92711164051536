import * as React from "react";
import { devicePixelRatio, IMAGE_FIT_CONTAIN, IMAGE_FIT_COVER, ImageSlide, useEventCallback, useLayoutEffect, } from "../../core/index.js";
export const isResponsiveImageSlide = (slide) => { var _a; return (((_a = slide.srcSet) === null || _a === void 0 ? void 0 : _a.length) || 0) > 0; };
export const ResponsiveImage = (props) => {
    var _a, _b;
    const [state, setState] = React.useState({});
    const { current, preload } = state;
    const { slide: image, rect, imageFit, render } = props;
    const srcSet = image.srcSet.sort((a, b) => a.width - b.width);
    const width = (_a = image.width) !== null && _a !== void 0 ? _a : srcSet[srcSet.length - 1].width;
    const height = (_b = image.height) !== null && _b !== void 0 ? _b : srcSet[srcSet.length - 1].height;
    const cover = image.imageFit === IMAGE_FIT_COVER || (image.imageFit !== IMAGE_FIT_CONTAIN && imageFit === IMAGE_FIT_COVER);
    const maxWidth = Math.max(...srcSet.map((x) => x.width));
    const targetWidth = Math.min((cover ? Math.max : Math.min)(rect.width, width * (rect.height / height)), maxWidth);
    const pixelDensity = devicePixelRatio();
    const handleSourceChange = useEventCallback(() => {
        var _a;
        const targetSource = (_a = srcSet.find((x) => x.width >= targetWidth * pixelDensity)) !== null && _a !== void 0 ? _a : srcSet[srcSet.length - 1];
        if (!current) {
            setState((prev) => ({ ...prev, current: targetSource.src }));
        }
        else if (srcSet.findIndex((x) => x.src === current) < srcSet.findIndex((x) => x === targetSource)) {
            setState((prev) => ({ ...prev, preload: targetSource.src }));
        }
    });
    useLayoutEffect(handleSourceChange, [rect === null || rect === void 0 ? void 0 : rect.width, rect === null || rect === void 0 ? void 0 : rect.height, pixelDensity, handleSourceChange]);
    const handlePreload = useEventCallback((currentPreload) => {
        if (currentPreload === preload) {
            setState((prev) => ({ ...prev, current: preload, preload: undefined }));
        }
    });
    const style = {
        WebkitTransform: "translateZ(0)",
        ...(rect.width / rect.height < width / height
            ? {
                width: "100%",
                height: "auto",
            }
            : { width: "auto", height: "100%" }),
    };
    return (React.createElement(React.Fragment, null,
        preload && preload !== current && (React.createElement(ImageSlide, { key: "preload", ...props, slide: { ...image, src: preload, srcSet: undefined }, style: { position: "absolute", visibility: "hidden", ...style }, onLoad: () => handlePreload(preload), render: {
                ...render,
                iconLoading: () => null,
                iconError: () => null,
            } })),
        current && (React.createElement(ImageSlide, { key: "current", ...props, slide: { ...image, src: current, srcSet: undefined }, style: style }))));
};
