import * as React from "react";
export const setRef = (ref, value) => {
    if (typeof ref === "function") {
        ref(value);
    }
    else if (ref) {
        ref.current = value;
    }
};
export const useForkRef = (refA, refB) => React.useMemo(() => refA == null && refB == null
    ? null
    : (refValue) => {
        setRef(refA, refValue);
        setRef(refB, refValue);
    }, [refA, refB]);
