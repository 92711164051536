import * as React from "react";
import { isDefined, isNumber, makeUseContext, useEvents } from "../../core/index.js";
const CaptionsContext = React.createContext(null);
export const useCaptions = makeUseContext("useCaptions", "CaptionsContext", CaptionsContext);
export const CaptionsContextProvider = ({ children }) => {
    const { subscribe } = useEvents();
    const [toolbarWidth, setToolbarWidth] = React.useState();
    React.useEffect(() => subscribe("toolbar-width", (event) => {
        if (!isDefined(event) || isNumber(event)) {
            setToolbarWidth(event);
        }
    }), [subscribe]);
    const context = React.useMemo(() => ({ toolbarWidth }), [toolbarWidth]);
    return React.createElement(CaptionsContext.Provider, { value: context }, children);
};
