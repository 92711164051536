import * as React from "react";
import { clsx, cssClass } from "../../core/index.js";
import { cssPrefix } from "./utils.js";
import { ThumbnailsTrack } from "./ThumbnailsTrack.js";
import { defaultThumbnailsProps } from "./Thumbnails.js";
export const ThumbnailsComponent = ({ thumbnails: thumbnailsProps, slides, index, carousel, animation, render, styles, children, }) => {
    const thumbnails = { ...defaultThumbnailsProps, ...thumbnailsProps };
    const ref = React.useRef(null);
    const track = (React.createElement(ThumbnailsTrack, { container: ref, slides: slides, thumbnails: thumbnails, carousel: carousel, animation: animation, render: render, startingIndex: index, thumbnailRect: { width: thumbnails.width, height: thumbnails.height }, styles: styles }));
    return (React.createElement("div", { ref: ref, className: clsx(cssClass(cssPrefix()), cssClass(cssPrefix(`${thumbnails.position}`))) },
        (thumbnails.position === "start" || thumbnails.position === "top") && track,
        React.createElement("div", { className: cssClass(cssPrefix("wrapper")) }, children),
        (thumbnails.position === "end" || thumbnails.position === "bottom") && track));
};
