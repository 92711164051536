import * as React from "react";
import { isImageSlide, makeUseContext, useEventCallback, useLayoutEffect, useLightboxState } from "../../core/index.js";
const ZoomContext = React.createContext(null);
export const useZoom = makeUseContext("useZoom", "ZoomContext", ZoomContext);
export const ZoomContextProvider = ({ slides, children }) => {
    const [isMinZoom, setIsMinZoom] = React.useState(false);
    const [isMaxZoom, setIsMaxZoom] = React.useState(false);
    const [isZoomSupported, setIsZoomSupported] = React.useState(false);
    const { state: { currentIndex }, } = useLightboxState();
    const updateZoomSupported = useEventCallback(() => setIsZoomSupported(slides.length > currentIndex && isImageSlide(slides[currentIndex])));
    useLayoutEffect(updateZoomSupported, [currentIndex, updateZoomSupported]);
    const context = React.useMemo(() => ({
        isMinZoom,
        isMaxZoom,
        isZoomSupported,
        setIsMinZoom,
        setIsMaxZoom,
    }), [isMinZoom, isMaxZoom, isZoomSupported]);
    return React.createElement(ZoomContext.Provider, { value: context }, children);
};
