import * as React from "react";
import { clsx, cssVar } from "../../core/index.js";
import { defaultCaptionsProps } from "./Captions.js";
import { cssPrefix } from "./utils.js";
export const Description = ({ description, descriptionTextAlign, descriptionMaxLines, styles, }) => (React.createElement("div", { style: styles.captionsDescriptionContainer, className: clsx(cssPrefix("captions_container"), cssPrefix("description_container")) },
    React.createElement("div", { className: cssPrefix("description"), style: {
            ...(descriptionTextAlign !== defaultCaptionsProps.descriptionTextAlign ||
                descriptionMaxLines !== defaultCaptionsProps.descriptionMaxLines
                ? {
                    style: {
                        [cssVar("slide_description_text_align")]: descriptionTextAlign,
                        [cssVar("slide_description_max_lines")]: descriptionMaxLines,
                    },
                }
                : null),
            ...styles.captionsDescription,
        } }, typeof description === "string"
        ? description.split("\n").flatMap((line, index) => [...(index > 0 ? [React.createElement("br", { key: index })] : []), line])
        : description)));
